<script setup lang="ts">
import type { CategoryImageNavigationItem } from '~/types/models/cms/cmsElementTypes';

defineProps<{
    categories: CategoryImageNavigationItem[];
}>();

const { pathToSeoUrl } = useLanguageSelect();

const getTarget = (category: CategoryImageNavigationItem): string => {
    return category.externalLink || category.linkNewTab ? '_blank' : '';
};

const getDestination = (category: CategoryImageNavigationItem): string => {
    if (category.externalLink) {
        return category.externalLink;
    }
    return pathToSeoUrl(category.categoryUrl);
};
</script>

<template>
    <div
        v-if="categories?.length"
        class="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
    >
        <NuxtLink
            v-for="category in categories"
            :key="category.categoryUrl"
            :target="getTarget(category)"
            :to="getDestination(category)"
            :title="category.categoryName"
            class="flex bg-gray-100 rounded-md h-35 align-center hover:text-brand-primary relative overflow-hidden"
        >
            <div class="flex-none my-auto w-[100px] sm:w-35">
                <SharedImage
                    class="mix-blend-multiply !w-full"
                    :alt="category.categoryName"
                    :media-src="category.mediaUrl || undefined"
                    width="140"
                    height="140"
                    sizes="140px"
                />
            </div>

            <div class="flex items-center justify-between w-full ml-3 sm:ml-5 pr-5 text-lg">
                <h3 class="heading-6 !font-normal break-word w-full pr-2">{{ category.categoryName }}</h3>
            </div>
            <SvgoArrowRightLong class="!h-4.5 !w-4.5 ml-auto absolute bottom-3 right-4" />
        </NuxtLink>
    </div>
</template>
